import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "empty-state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Pack order")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate(_ctx.isTrackingRequired ? "Tracking details are required in order to pack this shipment. Try generating a label from the selected carrier or enter a tracking code manually." : "Tracking details are missing in order to pack this shipment. Try generating a label from the selected carrier or enter a tracking code manually.")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_select, {
                  disabled: !_ctx.order.missingLabelImage,
                  label: _ctx.translate('Carrier'),
                  modelValue: _ctx.carrierPartyId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.carrierPartyId) = $event)),
                  interface: "popover",
                  onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCarrier(_ctx.carrierPartyId)))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facilityCarriers, (carrier) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: carrier.partyId,
                        value: carrier.partyId
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate(carrier.groupName)), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["disabled", "label", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                (_ctx.carrierMethods && _ctx.carrierMethods.length > 0)
                  ? (_openBlock(), _createBlock(_component_ion_select, {
                      key: 0,
                      disabled: !_ctx.order.missingLabelImage,
                      label: _ctx.translate('Method'),
                      modelValue: _ctx.shipmentMethodTypeId,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.shipmentMethodTypeId) = $event)),
                      interface: "popover"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carrierMethods, (method) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: _ctx.carrierMethods.partyId + method.shipmentMethodTypeId,
                            value: method.shipmentMethodTypeId
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate(method.description)), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["disabled", "label", "modelValue"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate('No shipment methods linked to', {carrierName: _ctx.getCarrierName()})), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_button, {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openShippingMethodDocumentReference())),
                        fill: "clear",
                        color: "medium",
                        slot: "end"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: _ctx.informationCircleOutline
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ], 64))
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  label: _ctx.translate('Tracking code'),
                  placeholder: _ctx.translate('enter code'),
                  modelValue: _ctx.trackingCode,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.trackingCode) = $event))
                }, null, 8, ["label", "placeholder", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Tracking URL:", { trackingUrl: _ctx.generateTrackingUrl() })), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_button, {
                  slot: "end",
                  fill: "clear",
                  size: "default",
                  disabled: !_ctx.trackingCode.trim() || !_ctx.getCarrierTrackingUrl(),
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.redirectToTrackingUrl()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Test")) + " ", 1),
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.openOutline,
                      slot: "end"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.isGeneratingShippingLabel)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_ion_spinner, { name: "crescent" }),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Generating label")), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_fab, {
      vertical: "bottom",
      horizontal: "end",
      slot: "fixed"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_fab_button, {
          disabled: _ctx.isGeneratingShippingLabel ? true : !_ctx.shipmentMethodTypeId,
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.confirmSave()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              icon: _ctx.isForceScanEnabled ? _ctx.barcodeOutline : _ctx.saveOutline
            }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    })
  ], 64))
}